@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

@font-face { font-family: 'Damasko'; src: url('assets/ttf/xspace-xspace-400.ttf'); } 
@layer base {
  html {

  }
  body{
    font-family: 'Montserrat', sans-serif !important;
    background-color: #f9f9f9 !important;
  }
  ::selection {
    background-color: Gainsboro;
  }
}

@layer components {
  .section-top-padding {
    @apply mt-32;
  }
  .nav-link {
    @apply px-4 py-3 text-[.8rem] text-[#7e4702] transition ease-in-out delay-100;
  }
  .top-title {
    @apply text-primary flex justify-center lg:justify-start uppercase text-sm font-medium tracking-wider text-center lg:text-left font-damasko;
  }
  .top-title-left {
    @apply text-primary flex justify-center  uppercase text-sm font-medium tracking-wider text-center lg:text-left font-damasko;
  }
  .main-title {
    @apply text-2xl font-bold text-center lg:text-left ;
  }
}


@layer base {
::-webkit-scrollbar-thumb{
@apply bg-transparent shadow-sm
}
::-webkit-scrollbar{
@apply w-2 bg-transparent
}
::-webkit-scrollbar-thumb{
@apply rounded-lg bg-primary  /*color trackbar*/
}
}
